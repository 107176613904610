<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-lg-6 mb-lg-0" style="display:flex;align-items: center">
          <div
              class="text-sm text-center copyright text-muted"
          >
            ©
            {{ new Date().getFullYear() }} Designed By
            <a
                href="https://www.region.mo/"
                class="font-weight-bold"
                target="_blank"
            >地域電腦有限公司</a
            >
          </div>
          <img
              src="@/assets/svg/facebook.svg"
              class="social-icon" alt="" @click="iosShare">
          <img
              src="@/assets/svg/twitter.svg"
              class="social-icon" alt="" @click="shareToTwitter">
        </div>
        <div class="col-lg-6">
          <ul
              class="nav nav-footer justify-content-center justify-content-lg-end"
          >
            <li class="nav-item">
              <a
                  href="https://www.region.mo/"
                  class="nav-link text-muted"
                  target="_blank"
              >地域電腦</a
              >
            </li>
            <li class="nav-item">
              <a
                  href="https://www.region.mo/%E9%97%9C%E6%96%BC%E6%88%91%E5%80%91"
                  class="nav-link text-muted"
                  target="_blank"
              >關於我們</a
              >
            </li>
            <li class="nav-item">
              <a
                  href="https://www.region.mo/%E8%81%AF%E7%B5%A1%E6%88%91%E5%80%91"
                  class="nav-link text-muted"
                  target="_blank"
              >聯絡我們</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  methods: {
    shareFBUrl() {
      FB.ui({
        method: 'share',
        href: 'https://region.mo', // 替換為您想讓用戶分享的實際URL
      }, function (response) {
        console.log(response)
        // 處理回調
      });
    },
    shareToTwitter() {
      const text = encodeURIComponent("地域電腦");
      const url = encodeURIComponent("https://region.mo");
      const twitterUrl = `https://twitter.com/intent/tweet?text=${text}&url=${url}`;
      window.open(twitterUrl, '_blank');
    },
    iosShare() {
      if (navigator.share) {
        navigator.share({
          title: 'WebShare API Demo',
          text: 'Check out this web site!',
          url: 'https://region.mo',
        }).then(() => console.log('Successful share')
        ).catch(
            (error) => alert('Error:' + error)
        );

      }
    }
  }
};
</script>
<style scoped>
.social-icon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
}
</style>
<template>

  <canvas ref="myCanvas" class="canvas-box" :style="{background:'url('+backgroundUrl+') no-repeat',backgroundSize:'cover'}">
  </canvas>
</template>
<script>
export default {
  name: "DrawSkeleton",
  props: {
    backgroundUrl: {
      type: String,
      default: "",
    },
    parentName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      pointPairs: [
        {start: 0, end: 1, color: 'pink'},
        {start: 1, end: 2, color: 'orange'},
        {start: 2, end: 3, color: 'yellow'},
        {start: 3, end: 4, color: 'lightYellow'},
        {start: 1, end: 5, color: 'darkSalmon'},
        {start: 5, end: 6, color: 'salmon'},
        {start: 6, end: 7, color: 'lightSalmon'},
        {start: 2, end: 8, color: 'darkTurquoise'},
        {start: 8, end: 9, color: 'turquoise'},
        {start: 9, end: 10, color: 'paleTurquoise'},
        {start: 5, end: 11, color: 'darkRed'},
        {start: 11, end: 12, color: 'red'},
        {start: 12, end: 13, color: 'orange'},
        {start: 0, end: 14, color: 'purple'},
        {start: 14, end: 16, color: 'purple'},
        {start: 0, end: 15, color: 'violet'},
        {start: 15, end: 17, color: 'violet'}
      ],
      canvasWidth: 960,
      canvasHeight: 540,
      canvas: undefined,
      ctx: undefined,
    };
  },
  mounted() {
    this.initCanvas();
    this.observeParentSize();

  },
  methods: {
    initCanvas() {
      this.adjustCanvasSize();
    },
    adjustCanvasSize(width = this.canvasWidth, height = this.canvasHeight) {
      const canvas = this.$refs.myCanvas;
      if (!canvas) return;

      let dpr = window.devicePixelRatio || 1;
      canvas.width = width * dpr;
      canvas.height = height * dpr;
      canvas.style.width = `${width}px`;
      canvas.style.height = `${height}px`;

      this.canvasWidth = width;
      this.canvasHeight = height;
      this.ctx = canvas.getContext('2d');
      this.ctx.scale(dpr, dpr);
      this.ctx.clearRect(0, 0, width, height);
    },
    observeParentSize() {
      const parent = this.$el.parentNode;
      const observer = new ResizeObserver(entries => {
        for (let entry of entries) {
          const { width, height } = entry.contentRect;
          this.adjustCanvasSize(width, height);
        }
      });
      observer.observe(parent);
    },
    drawLine(x0, y0, x1, y1) {
      this.ctx.beginPath();
      this.ctx.moveTo(x0, y0);
      this.ctx.lineTo(x1, y1);
      this.ctx.stroke();
    },
    drawSkeleton(lineWidth, points) {
      if((this.canvasWidth > 0) && (this.canvasWidth < 200)){
        lineWidth = 1;
      }
      if((this.canvasWidth > 200) && (this.canvasWidth < 400)){
        lineWidth = 2;
      }
      if((this.canvasWidth > 400) && (this.canvasWidth < 600)){
        lineWidth = 3;
      }
      if((this.canvasWidth > 600) && (this.canvasWidth < 800)){
        lineWidth = 4;
      }
      if((this.canvasWidth > 800) && (this.canvasWidth < 1000)){
        lineWidth = 5;
      }
      this.ctx.lineWidth = lineWidth;
      this.ctx.lineCap = 'round';
      let minX = 1;
      let minY = 1;
      this.pointPairs.forEach((pair) => {
        const startPoint = points[pair.start];
        const endPoint = points[pair.end];
        if (startPoint !== undefined && endPoint !== undefined) {
          if (endPoint.x < minX) minX = endPoint.x;
          if (endPoint.y < minY) minY = endPoint.y;
          this.ctx.strokeStyle = pair.color;
          this.drawLine(startPoint.x * this.canvasWidth, startPoint.y * this.canvasHeight,
              endPoint.x * this.canvasWidth, endPoint.y * this.canvasHeight);
        }
      })
    },

    drawSkeletonReplay(frame, scaleX, scaleY) {
      this.ctx.lineCap = 'round';
      let lineWidth = 1;
      if((this.canvasWidth > 0) && (this.canvasWidth < 200)){
        lineWidth = 1;
      }
      if((this.canvasWidth > 200) && (this.canvasWidth < 400)){
        lineWidth = 2;
      }
      if((this.canvasWidth > 400) && (this.canvasWidth < 600)){
        lineWidth = 3;
      }
      if((this.canvasWidth > 600) && (this.canvasWidth < 800)){
        lineWidth = 4;
      }
      if((this.canvasWidth > 800) && (this.canvasWidth < 1000)){
        lineWidth = 5;
      }
      this.ctx.lineWidth = lineWidth;
      this.ctx.strokeStyle = 'red';
      this.pointPairs.forEach(pair => {
        const startPoint = frame[pair.start];
        const endPoint = frame[pair.end];
        if (startPoint !== undefined && endPoint !== undefined) {
          this.ctx.strokeStyle = pair.color;
          this.drawLine(startPoint.x * scaleX, startPoint.y * scaleY, endPoint.x * scaleX, endPoint.y * scaleY);
        }
      })

    },
    cleanCanvas(){
      this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    }
  }
};
</script>
<style scoped>
.canvas-box {
  width: 100%;
  height: 100%;
  background: #000000;
  border-radius: 8px;
  transform: scaleX(-1);
}
</style>

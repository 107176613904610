<template>
  <sidenav
      v-if="showSidenav"
      :custom_class="color"
      :class="[isRTL ? 'fixed-end' : 'fixed-start']"
  />
  <main
      class="main-content position-relative max-height-vh-100 h-100 overflow-x-hidden"
  >
    <!-- nav -->
    <navbar
        v-if="showNavbar"
        :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']"
        :color="isAbsolute ? 'text-white opacity-8' : ''"
        :min-nav="navbarMinimize"
    />
    <router-view/>
    <app-footer v-show="showFooter"/>
  </main>

  <camera-replay ref="replayBox"></camera-replay>

</template>
<script lang="jsx">
import Sidenav from "./examples/Sidenav";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import {mapMutations, mapState} from "vuex";
import {MessagePlugin} from "tdesign-vue-next";
import CameraReplay from "@/components/CameraReplay.vue";
import {Stomp} from "@stomp/stompjs";
import UserDataStore from "@/utils/UserDataStore";

export default {
  name: "App",
  components: {
    Sidenav,
    Navbar,
    AppFooter,
    CameraReplay
  },
  data() {
    return {
      messages: "",
      stompClient: null,
      messageList: [],
      text: "",
      showReplay: false,
      alertContentValueMap: {
        5: "舉手請求幫助",
        1: "跌倒",
        2: "進入限制區域"
      },
      notificationMap: {},
      canShowMessage: true,
    }
  },
  computed: {
    ...mapState([
      "isRTL",
      "color",
      "isAbsolute",
      "isNavFixed",
      "navbarFixed",
      "absolute",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton"
    ])
  },
  beforeMount() {
    const sidenav = document.getElementsByClassName("g-sidenav-show")[0];

    if (window.innerWidth > 1200) {
      sidenav.classList.add("g-sidenav-pinned");
    }
  },
  mounted() {
    this.loadFacebookSDK();
    this.initWS();
    UserDataStore.asyncVuexWithSession();
    if (!sessionStorage.getItem("token") || sessionStorage.getItem("token") === "") {
      MessagePlugin.error("請登錄！");
      setTimeout(() => {
        this.$router.push({path: "/login"});
      }, 300)
    }
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
    handleResize(e) {
      this.$store.commit("changeWindowResizeEvent", e);
    },
    initWS: function () {
      // let socket = new SockJS("/api/ws")
      const stompClient = Stomp.over(function () {
        const ws = new WebSocket('wss://healthcare.region.mo:8766/ws?wsToken=null');
        // const ws = new WebSocket('ws://localhost:8001/ws?wsToken=null');

        ws.onopen = function () {
          console.log('WebSocket connection established.');
        };

        ws.onerror = function (error) {
          console.error('WebSocket connection error:', error);
        };

        ws.onclose = function (event) {
          console.log('WebSocket connection closed:', event);
        };
        return ws;
        // return new WebSocket("ws://localhost:8001/ws?wsToken=" + sessionStorage.getItem("systemToken"));
      });
      stompClient.connect({}, this.onConnected, this.onDisconnected);
      // this.stompClient.debug = this.debugCallback;
      this.stompClient = stompClient;
      this.stompClient.debug = function () {
      };
      let that = this;
      this.heartCheck = {
        timeout: 15000,
        timeoutObj: null,
        serverTimeoutObj: null,
        reset: function () {
          clearTimeout(this.timeoutObj);
          clearInterval(this.serverTimeoutObj);
          return this;
        },
        start: function () {
          let self = this;
          this.timeoutObj = setTimeout(function () {
            //这里发送一个心跳，后端收到后，返回一个心跳消息，
            //onmessage拿到返回的心跳就说明连接正常
            that.stompClient.send("/app/chat.sendUToUMessage", {}, JSON.stringify({
              content: 'client send keepalive message!',
              type: 9
            }))
            self.serverTimeoutObj = setInterval(function () {
              that.stompClient.send("/app/chat.sendUToUMessage", {}, JSON.stringify({
                content: 'client send keepalive message!',
                type: 9
              }))
            }, self.timeout);
          }, this.timeout);
        }
      };
    },
    onConnected() {
      this.stompClient.subscribe("/topic/public", this.onMessage);
      this.stompClient.subscribe("/user/8BF53D27B06B4E288620F6FAD3FD8018/private", this.onMessage);
      this.heartCheck.reset().start();
      // NotifyPlugin('success', {
      //   title: `成功！`,
      //   duration:0,
      //   closeBtn:true,
      //   content: `链接成功！`,
      //   footer:footer
      // });
      // this.stompClient.send("/app/chat.sendMessage",{},JSON.stringify({wsId:'hello'}))
    },
    // sendMessage() {
    //   this.stompClient.send("/app/SendQuery", {}, JSON.stringify({
    //     from: "8BF53D27B06B4E288620F6FAD3FD8018",
    //     toId: "server",
    //     content: this.text
    //   }))
    //   this.messageList.push({
    //     message: this.text,
    //     isGPT: false
    //   })
    //   this.messageList.push({
    //     message: "",
    //     isGPT: true
    //   })
    // },
    onMessage(msg) {
      let that = this;
      if (this.canShowMessage) {
        this.canShowMessage = false;
        let alert = JSON.parse(msg.body).content;
        this.notificationMap[alert.alertId] = this.$notify.error({
          title: this.alertContentValueMap[alert.actionType],
          duration: 0,
          closeBtn: true,
          onCloseBtnClick: () => {
            that.canShowMessage = true
          },
          // offset: [-(window.innerWidth/2-180), window.innerHeight - 69],
          offset: [-(window.innerWidth / 2 - 180), window.innerHeight / 4],
          content: `監測到有人在 ${alert.roomName} ${this.alertContentValueMap[alert.alertType]}！請立即查看！\n 發生時間：${alert.timeStamp}`,
          footer: () => (
              <div slot="footer" class="t-notification__detail">
                <div class="nf-button" onClick={() => this.showReplayBox(alert.alertId)}>
                  查看详情
                </div>
              </div>
          )
        })
        setTimeout(() => {
          this.canShowMessage = true;
        }, 2 * 60 * 1000)
      }

    },
    onDisconnected() {
      MessagePlugin.warning("服务器连接失败！")
    },
    showReplayBox(id) {
      this.$notify.close(this.notificationMap[id]);
      this.$refs.replayBox.showDialog(id);
    },
    loadFacebookSDK() {
      // 檢查是否已經加載Facebook SDK
      if (document.getElementById('facebook-jssdk')) {
        return;
      }
      let js, fjs = document.getElementsByTagName('script')[0];
      if (document.getElementById('facebook-jssdk')) {
        return;
      }
      js = document.createElement('script');
      js.id = 'facebook-jssdk';
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);

      window.fbAsyncInit = function () {
        FB.init({
          appId: '393986261841574', // 替換為您的Facebook應用ID
          cookie: true,
          xfbml: true,
          version: 'v11.0' // 使用的Facebook API版本
        });
        FB.AppEvents.logPageView();
      };
    }


  }
};
</script>
<style>
.dataTable-pagination-list .active a {
  background-image: linear-gradient(
      195deg,
      #66bb6a 0%,
      #43a047 100%
  ) !important;
}

.t-notification {
  width: auto;
  min-width: 360px;
}

.t-notification__content {
  white-space: pre;
}

.t-dialog__ctx.t-dialog__ctx--fixed {
  z-index: 6200;
}
</style>

<style src="./assets/css/index.css"></style>

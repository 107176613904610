import {createStore} from "vuex";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";

export default createStore({
    state: {
        hideConfigButton: false,
        isPinned: false,
        showConfig: false,
        sidebarType: "bg-gradient-dark",
        isRTL: false,
        color: "success",
        isNavFixed: false,
        isAbsolute: false,
        showNavs: true,
        showSidenav: true,
        showNavbar: true,
        showFooter: true,
        showMain: true,
        isDarkMode: false,
        navbarFixed:
            "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
        absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
        bootstrap,
        username: "未登錄",
        token: "",
        roles: "0",
        nickname: "未登錄",
        phoneNumber: "-----",
        uid: "",
        active: false,
        avatar: "https://letoy-resource-1311015785.cos.ap-nanjing.myqcloud.com/CarProjectFiles/UserAvatars/B4678DB5C60C470881BDE01EBB348387.jpg",
        maxRole:0,
        minRole:0,

        windowResizeEvent: {},
    },
    mutations: {
        toggleConfigurator(state) {
            state.showConfig = !state.showConfig;
        },
        toggleEveryDisplay(state) {
            state.showNavbar = !state.showNavbar;
            state.showSidenav = !state.showSidenav;
            state.showFooter = !state.showFooter;
        },
        toggleHideConfig(state) {
            state.hideConfigButton = !state.hideConfigButton;
        },
        setIsPinned(state, payload) {
            state.isPinned = payload;
        },
        color(state, payload) {
            state.color = payload;
        },
        navbarMinimize(state) {
            const sidenavShow = document.getElementsByClassName("g-sidenav-show")[0];

            if (sidenavShow.classList.contains("g-sidenav-pinned")) {
                sidenavShow.classList.remove("g-sidenav-pinned");
                sidenavShow.classList.add("g-sidenav-hidden");
                state.isPinned = true;
            } else {
                sidenavShow.classList.remove("g-sidenav-hidden");
                sidenavShow.classList.add("g-sidenav-pinned");
                state.isPinned = false;
            }
        },
        navbarFixed(state) {
            state.isNavFixed = state.isNavFixed === false;
        },
        changeUsername(state, username) {
            state.username = username;
        },
        changeToken(state, token) {
            state.token = token;
        },
        changeRoles(state, roles) {
            state.roles = roles;
        },
        changeNickname(state, nickname) {
            state.nickname = nickname;
        },
        changePhoneNumber(state, phoneNumber) {
            state.phoneNumber = phoneNumber;
        },
        changeUid(state, uid) {
            state.uid = uid;
        },
        changeActive(state, active) {
            state.active = active;
        },
        changeAvatar(state, avatar) {
            state.avatar = avatar;
        },
        changeMaxRole(state, maxRole) {
            state.maxRole = maxRole;
        },
        changeMinRole(state, minRole) {
            state.minRole = minRole;
        },
        changeWindowResizeEvent(state, windowResizeEvent) {
            state.windowResizeEvent = windowResizeEvent;
        },
    },
    actions: {
        setColor({commit}, payload) {
            commit("color", payload);
        },
        changeUsername(context, username) {
            context.commit('changeUsername', username)
        },
        changeToken(context, token) {
            context.commit('changeToken', token)
        },
        changeRoles(context, roles) {
            context.commit('changeRoles', roles)
        },
        changeNickname(context, nickname) {
            context.commit('changeNickname', nickname)
        },
        changePhoneNumber(context, phoneNumber) {
            context.commit('changePhoneNumber', phoneNumber)
        },
        changeUid(context, uid) {
            context.commit('changeUid', uid)
        },
        changeActive(context, active) {
            context.commit('changeActive', active)
        },
        changeAvatar(context, avatar) {
            context.commit('changeAvatar', avatar)
        },
        changeMaxRole(context, maxRole) {
            context.commit('changeMaxRole', maxRole)
        },
        changeMinRole(context, minRole) {
            context.commit('changeMinRole', minRole)
        },
        changeWindowResizeEvent(context, windowResizeEvent) {
            context.commit('changeWindowResizeEvent', windowResizeEvent)
        },
    },
    getters: {
        getPinned(state) {
            return state.isPinned;
        },
        getUsername(state) {
            return state.username;
        },
        getToken(state) {
            return state.token;
        },
        getRoles(state) {
            return state.roles;
        },
        getNickname(state) {
            return state.nickname;
        },
        getPhoneNumber(state) {
            return state.phoneNumber;
        },
        getUid(state) {
            return state.uid;
        },
        getActive(state) {
            return state.active;
        },
        getAvatar(state) {
            return state.avatar;
        },
        getMaxRole(state) {
            return state.maxRole;
        },
        getMinRole(state) {
            return state.minRole;
        },
        getWindowResizeEvent(state) {
            return state.windowResizeEvent;
        },
    }
});

export const chatStore = createStore()

export function setupStore(app) {
    app.use(chatStore)
}

export * from './modules'
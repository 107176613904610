import store from "./../store";
const UserDataStore = {
    storeUserLoginInfoSession(userInfo) {
        //存Session
        sessionStorage.setItem("active", userInfo.active);
        sessionStorage.setItem("avatar", userInfo.avatar);
        sessionStorage.setItem("maxRole", userInfo.maxRole);
        sessionStorage.setItem("minRole", userInfo.minRole);
        sessionStorage.setItem("nickname", userInfo.nickname);
        sessionStorage.setItem("phoneNumber", userInfo.phoneNumber);
        sessionStorage.setItem("roles", userInfo.roles);
        sessionStorage.setItem("token", userInfo.token);
        sessionStorage.setItem("uid", userInfo.uid);
        sessionStorage.setItem("username", userInfo.username);

    },
    storeUserLoginInfoVuex(userInfo) {
        //存Session
        store.commit("changeActive", userInfo.active);
        store.commit("changeAvatar", userInfo.avatar);
        store.commit("changeMaxRole", userInfo.maxRole);
        store.commit("changeMinRole", userInfo.minRole);
        store.commit("changeNickname", userInfo.nickname);
        store.commit("changePhoneNumber", userInfo.phoneNumber);
        store.commit("changeRoles", userInfo.roles);
        store.commit("changeToken", userInfo.token);
        store.commit("changeUid", userInfo.uid);
        store.commit("changeUsername", userInfo.username);
    },
    asyncVuexWithSession() {
        store.commit("changeActive", sessionStorage.getItem("active") ? sessionStorage.getItem("active") : false);
        store.commit("changeAvatar", sessionStorage.getItem("avatar") ? sessionStorage.getItem("avatar") : "https://letoy-resource-1311015785.cos.ap-nanjing.myqcloud.com/CarProjectFiles/UserAvatars/B4678DB5C60C470881BDE01EBB348387.jpg");
        store.commit("changeMaxRole", sessionStorage.getItem("maxRole") ? sessionStorage.getItem("maxRole") : 0);
        store.commit("changeMinRole", sessionStorage.getItem("minRole") ? sessionStorage.getItem("minRole") : 0);
        store.commit("changeNickname", sessionStorage.getItem("nickname") ? sessionStorage.getItem("nickname") : "未登錄");
        store.commit("changePhoneNumber", sessionStorage.getItem("phoneNumber") ? sessionStorage.getItem("phoneNumber") : "--------");
        store.commit("changeRoles", sessionStorage.getItem("roles") ? sessionStorage.getItem("roles") : "0");
        store.commit("changeToken", sessionStorage.getItem("token") ? sessionStorage.getItem("token") : "");
        store.commit("changeUid", sessionStorage.getItem("uid") ? sessionStorage.getItem("uid") : "");
        store.commit("changeUsername", sessionStorage.getItem("username") ? sessionStorage.getItem("username") : "未登錄");

    },
};
export default UserDataStore;
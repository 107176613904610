<template>
  <div
      id="sidenav-collapse-main"
      class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100 my-nav-bar"
  >
    <ul class="navbar-nav">

      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse
            collapse-ref="brooklynAlice"
            :nav-text="nickname"
            :class="getRoute() === 'brooklynAlice' ? 'active' : ''"
        >
          <template #icon>
            <img :src="avatar" class="avatar"  alt=""/>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-item
                  :to="{path:'/info/MultiScreen'}"
                  mini-icon="badge"
                  text="個人資料"
              />
              <sidenav-item
                  :to="{path:'/info/MultiScreen'}"
                  mini-icon="settings"
                  text="系統設置"
              />
              <sidenav-item
                  :to="{path:'/info/MultiScreen'}"
                  mini-icon="logout"
                  text="退出系統"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <hr class="horizontal light mt-2 mb-2"/>


      <li class="nav-item">
        <sidenav-collapse
            collapse-ref=""
            :class="getFullRoutes() === '/info/MultiScreen' ? 'active' : ''"
            nav-text="查看院舍"
            :collapse="false"
            collapse-name="InTellingAssist"
            url="#"
            :aria-controls="''"
            :to="{path:'/info/MultiScreen'}"
        >
          <template #icon>
            <span class="material-symbols-outlined">desktop_windows</span>
          </template>
        </sidenav-collapse>
      </li>


      <li class="nav-item">
        <sidenav-collapse
            collapse-ref=""
            :class="getFullRoutes() === '/dashboards/Home' ? 'active' : ''"
            nav-text="數據統計"
            :collapse="false"
            collapse-name="Home"
            url="#"
            :aria-controls="''"
            :to="{path:'/dashboards/Home'}"
        >
          <template #icon>
            <span class="material-symbols-outlined">apps</span>
          </template>
        </sidenav-collapse>
      </li>

<!--      <li class="nav-item">-->
<!--        <sidenav-collapse-->
<!--            collapse-ref=""-->
<!--            :class="getFullRoutes() === '/info/RoomsInfo' ? 'active' : ''"-->
<!--            nav-text="院舍管理"-->
<!--            :collapse="false"-->
<!--            collapse-name="RoomsInfo"-->
<!--            url="#"-->
<!--            :aria-controls="''"-->
<!--            :to="{path:'/info/RoomsInfo'}"-->
<!--        >-->
<!--          <template #icon>-->
<!--            <span class="material-symbols-outlined">living</span>-->
<!--          </template>-->
<!--        </sidenav-collapse>-->
<!--      </li>-->




      <li class="nav-item">
        <sidenav-collapse
            collapse-ref=""
            :class="getFullRoutes() === '/info/HistoryData' ? 'active' : ''"
            nav-text="歷史紀錄"
            :collapse="false"
            collapse-name="HistoryData"
            url="#"
            :aria-controls="''"
            :to="{path:'/info/HistoryData'}"
        >
          <template #icon>
            <span class="material-symbols-outlined">history</span>
          </template>
        </sidenav-collapse>
      </li>


      <li class="nav-item">
        <sidenav-collapse
            collapse-ref=""
            :class="getFullRoutes() === '/info/RadarInfo' ? 'active' : ''"
            nav-text="雷達設備"
            :collapse="false"
            collapse-name="HistoryData"
            url="#"
            :aria-controls="''"
            :to="{path:'/info/RadarInfo'}"
        >
          <template #icon>
            <span class="material-symbols-outlined">radar</span>
          </template>
        </sidenav-collapse>
      </li>

<!--      <li class="nav-item">-->
<!--        <sidenav-collapse-->
<!--            collapse-ref=""-->
<!--            :class="getFullRoutes() === '/info/FallDownEvents' ? 'active' : ''"-->
<!--            nav-text="跌倒事件"-->
<!--            :collapse="false"-->
<!--            collapse-name="FallDownEvents"-->
<!--            url="#"-->
<!--            :aria-controls="''"-->
<!--            :to="{path:'/info/FallDownEvents'}"-->
<!--        >-->
<!--          <template #icon>-->
<!--            <span class="material-symbols-outlined">falling</span>-->
<!--          </template>-->
<!--        </sidenav-collapse>-->
<!--      </li>-->

<!--      <li class="nav-item">-->
<!--        <sidenav-collapse-->
<!--            collapse-ref=""-->
<!--            :class="getFullRoutes() === '/info/StampDemo' ? 'active' : ''"-->
<!--            nav-text="獎章測試"-->
<!--            :collapse="false"-->
<!--            collapse-name="StampDemo"-->
<!--            url="#"-->
<!--            :aria-controls="''"-->
<!--            :to="{path:'/info/StampDemo'}"-->
<!--        >-->
<!--          <template #icon>-->
<!--            <span class="material-symbols-outlined">stars</span>-->
<!--          </template>-->
<!--        </sidenav-collapse>-->
<!--      </li>-->


<!--      <li class="nav-item">-->
<!--        <sidenav-collapse-->
<!--            collapse-ref=""-->
<!--            :class="getFullRoutes() === '/dashboards/EmployeeManagement' ? 'active' : ''"-->
<!--            nav-text="員工管理"-->
<!--            :collapse="false"-->
<!--            collapse-name="EmployeeManagement"-->
<!--            url="#"-->
<!--            :aria-controls="''"-->
<!--            :to="{path:'/dashboards/EmployeeManagement'}"-->
<!--        >-->
<!--          <template #icon>-->
<!--            <span class="material-symbols-outlined">groups</span>-->
<!--          </template>-->
<!--        </sidenav-collapse>-->
<!--      </li>-->


      <!--      <li class="nav-item">-->
      <!--        <sidenav-collapse-->
      <!--            collapse-ref=""-->
      <!--            :class="getFullRoutes() === '/info/MQTTDemo' ? 'active' : ''"-->
      <!--            nav-text="攝像頭實時畫面"-->
      <!--            :collapse="false"-->
      <!--            collapse-name="InTellingAssist"-->
      <!--            url="#"-->
      <!--            :aria-controls="''"-->
      <!--            :to="{path:'/info/MQTTDemo'}"-->
      <!--        >-->
      <!--          <template #icon>-->
      <!--            <span class="material-symbols-outlined">videocam</span>-->
      <!--          </template>-->
      <!--        </sidenav-collapse>-->
      <!--      </li>-->


      <!--      <li class="nav-item" :class="getRoute() === 'spts' ? 'active' : ''">-->
      <!--        <sidenav-collapse-->
      <!--            collapse-ref="dashboardsExamples"-->
      <!--            nav-text="攝像頭"-->
      <!--            :class="getRoute() === 'spts' ? 'active' : ''"-->
      <!--        >-->
      <!--          <template #icon>-->
      <!--            <span class="material-symbols-outlined">videocam</span>-->
      <!--          </template>-->
      <!--          <template #list>-->
      <!--            <ul class="nav pe-0">-->
      <!--              &lt;!&ndash; nav links &ndash;&gt;-->
      <!--              <sidenav-item-->
      <!--                  :to="{ path:'/spts/MQTTDemo' }"-->
      <!--                  mini-icon="攝"-->
      <!--                  text="攝像頭實時畫面"-->
      <!--              />-->
      <!--              <sidenav-item-->
      <!--                  :to="{ name: 'Sales' }"-->
      <!--                  mini-icon="S"-->
      <!--                  text="Sales"-->
      <!--              />-->
      <!--            </ul>-->
      <!--          </template>-->
      <!--        </sidenav-collapse>-->
      <!--      </li>-->

    </ul>
  </div>
</template>
<script>
// import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import {mapState} from "vuex";
import SidenavItem from "@/examples/Sidenav/SidenavItem.vue";

export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    SidenavCollapse,
  },
  data() {
    return {
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active",
    };
  },
  computed: {
    ...mapState(["isRTL"]),
    avatar:{
      get(){
        return this.$store.getters.getAvatar;
      }
    },
    nickname:{
      get(){
        return this.$store.getters.getNickname;
      }
    },
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    getFullRoutes() {
      return this.$route.fullPath;
    }
  }
};
</script>

<template>
  <div>
  <t-dialog width="60vw" top="30px"
            :header="alertInfo.room_name+' '+eventTypeMap[alertInfo.event_type]"
            :close-on-overlay-click="true"
            :visible="myVisible"
            :footer="null"
            :on-close="closeBox"
  >
    <div class="replay-box">
      <div v-if="!isPlay" class="play-box">
        <img src="./../assets/svg/play.svg" class="play-icon" alt="" @click="playFile">
      </div>
      <div class="views online-border" >
        <draw-skeleton ref="myReplay" :background-url="backgroundImg"
        ></draw-skeleton>
        <div class="title">{{ camera.room_name }} {{ camera.friendly_name }}</div>
        <div class="right-top">
          <t-tag theme="success" size="large">REPLAY</t-tag>
        </div>
        <t-progress class="replay-progress" :label="false" :status="'success'" theme="line" :percentage="progress"/>
      </div>
      <div v-if="!isPlay" class="box-loading-overlay" @click="playFile">
      </div>
      <div v-if="loadingMap[camera.serial_number] === true" class="box-loading-overlay">
        <div class="white-div lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
    <div class="l-row">
      <t-button size="small" style="width: 90px;margin-left: auto;margin-top: 20px;margin-right: 5px;" theme="success" @click="resolveEvent">跟進</t-button>
    </div>
  </t-dialog>
    <my-resolve-box ref="myResolve" :alert-id="alertId"></my-resolve-box>
  </div>
</template>
<script>
import {PlayCircleIcon, StopCircleIcon} from "tdesign-icons-vue-next";
import AlthumApi from "@/utils/AlthumApi";
import DrawSkeleton from "@/pages/DrawSkeleton.vue";
import MyResolveBox from "@/pages/components/MyResolveBox.vue";
export default {
  name: "MultiScreen",
  // eslint-disable-next-line vue/no-unused-components
  components: {MyResolveBox, DrawSkeleton, StopCircleIcon, PlayCircleIcon},
  props:{
    alertId: {
      type: String,
      default:"",
    },
  },
  data() {
    return {
      client: undefined,
      currentPic: {},
      cameraId: "",
      backgroundImg: "",
      alertMap: {},
      loadingMap: {},
      res: [],
      times: 16,
      picList: [],
      progress: 0,
      pointPairs: [
        {start: 0, end: 1, color: 'pink'},
        {start: 1, end: 2, color: 'orange'},
        {start: 2, end: 3, color: 'yellow'},
        {start: 3, end: 4, color: 'lightYellow'},
        {start: 1, end: 5, color: 'darkSalmon'},
        {start: 5, end: 6, color: 'salmon'},
        {start: 6, end: 7, color: 'lightSalmon'},
        {start: 2, end: 8, color: 'darkTurquoise'},
        {start: 8, end: 9, color: 'turquoise'},
        {start: 9, end: 10, color: 'paleTurquoise'},
        {start: 5, end: 11, color: 'darkRed'},
        {start: 11, end: 12, color: 'red'},
        {start: 12, end: 13, color: 'orange'},
        {start: 0, end: 14, color: 'purple'},
        {start: 14, end: 16, color: 'purple'},
        {start: 0, end: 15, color: 'violet'},
        {start: 15, end: 17, color: 'violet'}
      ],
      isPlay: false,
      playConfig: {},
      canvasWidth: 960,
      canvasHeight: 540,
      showPicList: false,
      canvas: undefined,
      ctx: undefined,
      subscribeTopics: [],
      publishTopics: [],
      backgroundUrl: "",
      backgroundUrlMap: {},
      accountInfo: {},
      cameraData: {},
      mqttInit: false,
      publishToken: false,
      heartBreakInterval: undefined,
      time: 0,
      camera: {},
      cameras: [],
      cameraIntervals: [],
      finishLoading: false,
      alertInfo: {},
      myVisible:false,
      eventTypeMap:{
        1:'跌倒警報',
        2:'限制訪問警報',
        3:'非法入侵警報',
        4:'火災警報',
        5:'揮手警報',
      },
      player:{},
      resolveVisible:false,
      currentAlertId:"",
      callBackFun:{}
    };
  },
  watch: {
    testValue(){
      console.log(this.visible)
      return this.visible;
    }
  },
  mounted() {

  },
  methods: {
    getCameraBackgroundImageURL(id) {
      AlthumApi.getCameraBackgroundImageURL(id)
          .then((res) => {
            this.backgroundImg = JSON.parse(res.data).background_url;
          })
    },
    getSkeletonRecords() {
      AlthumApi.getSkeletonRecords("2023-11-20 16:25:45", "2023-11-20 16:30:45")
          .then((res) => {
            console.log(JSON.parse(res.data))
          })
    },
    getAlertByAlertId(id) {
      AlthumApi.getAlertByAlertId(id)
          .then((res) => {
            this.myVisible = true;
            setTimeout(()=>{
              let data = JSON.parse(res.data);
              this.alertInfo = data.alert;
              let alert = data.alert;
              this.getCameraBackgroundImageURL(alert.camera_id);
              this.transformRecord(data);
            },500)
          })
    },
    getAlerts() {
      AlthumApi.getAlerts()
          .then((res) => {
            console.log(JSON.parse(res.data))
          })
    },
    transformRecord(res) {
      const byteList = this.base64ToArrayBuffer(res.alert.skeleton_file);
      // const canvas = document.getElementById('canvas');
      if (this.parseStringInt32(byteList, 0) === 3) {
        const width = this.parseStringInt16(byteList, 16);
        const height = this.parseStringInt16(byteList, 18);
        const canvasWidth = this.$refs.myReplay.canvasWidth;
        const canvasHeight = this.$refs.myReplay.canvasHeight;
        const scaleX = canvasWidth / width;
        const scaleY = canvasHeight / height;
        const numFrames = this.parseStringInt16(byteList, 26);
        if (numFrames > 0) {
          let epochTime;
          let curIndex = 28;
          const frames = [];
          for (let i = 0; i < numFrames; i++) {
            epochTime = this.parseStringInt16(byteList, curIndex);
            curIndex += 3;
            const numParts = this.parseStringInt8(byteList, curIndex);
            curIndex += 17;
            const list = []
            for (let j = 0; j < numParts; j++) {
              const index = this.parseStringInt8(byteList, curIndex);
              curIndex += 2;
              const xCoord = this.parseStringInt16(byteList, curIndex);
              curIndex += 2;
              const yCoord = this.parseStringInt16(byteList, curIndex);
              curIndex += 2;
              list[index] = {x: xCoord, y: yCoord};
            }
            frames.push(list);
          }
          let interval = epochTime / numFrames;
          this.playConfig = {
            interval,
            scaleX,
            scaleY,
            frames
          }
        }
      }
    },
    playFile() {
      if (!this.isPlay) {
        this.isPlay = true;
        let i = this.playConfig.frames.length - 1;
        let player = setInterval(() => {
          this.$refs.myReplay.cleanCanvas();
          if (i > 0) {
            this.progress = (1 - i / this.playConfig.frames.length) * 100;
            this.$refs.myReplay.drawSkeletonReplay(this.playConfig.frames[i], this.playConfig.scaleX, this.playConfig.scaleY);
            i--;
          } else {
            clearInterval(player);
            this.isPlay = false;
            this.progress = 100;
          }
        }, this.playConfig.interval);
        this.player = player;
      }

    },
    base64ToArrayBuffer(base64String) {
      const binary_string = window.atob(base64String);
      const len = binary_string.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes;
    },
    parseStringInt8(stringData, startIndex) {
      if (startIndex + 2 <= stringData.length) {
        const t = stringData.slice(startIndex, startIndex + 2);
        return new DataView(t.buffer).getInt8(0, true);
      }
    },
    parseStringInt16(stringData, startIndex) {
      if (startIndex + 2 <= stringData.length) {
        const t = stringData.slice(startIndex, startIndex + 2);
        return new DataView(t.buffer).getInt16(0, true);
      }
    },
    parseStringInt32(stringData, startIndex) {
      if (startIndex + 4 <= stringData.length) {
        const t = stringData.slice(startIndex, startIndex + 4);
        return new DataView(t.buffer).getInt32(0, true);
      }
    },
    closeDialog(){
      this.myVisible = false;
    },
    showDialog(id,callBackFun){
      this.currentAlertId = id;
      this.getAlertByAlertId(id);
      this.callBackFun = callBackFun;
    },
    closeBox(){
      clearInterval(this.player);
      this.progress = 0;
      this.isPlay = false;
      this.$refs.myReplay.cleanCanvas();
      this.myVisible = false;
      this.alertInfo = {};
      this.callBackFun();
    },
    resolveEvent(){
      this.$refs.myResolve.resolveEvent(this.currentAlertId,this.alertInfo,()=>{});
    }
  }
};
</script>
<style src="./../assets/css/MultiScreen.css"></style>
<style src="./../assets/css/CameraReplay.css"></style>

<template>
  <div>
    <t-dialog :visible="resolveVisible" :on-close="closeResolve" :header="`跟進事件`" :footer="null">
      <div class="l-row">
        <div class="d-label">發生時間：</div>
        <div class="d-content">{{ formatDate(alertInfo.time) }}</div>
      </div>
      <div class="l-row">
        <div class="d-label">發生地點：</div>
        <div class="d-content">{{ alertInfo.room_name }}
          <t-tag v-if="alertInfo.event_type === 5" size="small" theme="primary">舉手請求幫助</t-tag>
          <t-tag v-if="alertInfo.event_type === 1" size="small" theme="danger">跌倒</t-tag>
          <t-tag v-if="alertInfo.event_type === 2" size="small" theme="warning">進入限制區域</t-tag>
        </div>
      </div>
      <div class="l-row">
        <div class="d-label">關聯人員：</div>
        <div class="d-content">{{ alertInfo.person_name === 'Someone' ? '訪客' : alertInfo.person_name }}</div>
      </div>
      <div class="l-row">
        <div class="d-label">跟進狀態：</div>
        <t-tag v-if="resolveInfo.status === 1" size="small" theme="danger">未處理</t-tag>
        <t-tag v-if="resolveInfo.status === 2" size="small" theme="success">已處理</t-tag>
        <t-tag v-if="resolveInfo.status === 3" size="small" theme="primary">無需處理</t-tag>
      </div>
      <div class="l-row">
        <div class="d-label">操作人員：</div>
        <div class="d-content">{{ nickname }}</div>
      </div>
      <div class="l-row">
        <div class="d-label">處理方式：</div>
      </div>
      <div class="l-row" style="margin-top: 10px">
        <t-textarea v-model="resolveContent"></t-textarea>
      </div>
      <div class="l-row" style="justify-content: space-evenly;margin-top: 20px;">
        <t-button size="small" style="width: 100px;" theme="primary" @click="submit(1)">無需處理</t-button>
        <t-button size="small" style="width: 100px;" theme="success" @click="submit(2)">處理</t-button>
      </div>
    </t-dialog>
  </div>
</template>
<script>
import ServerApi from "@/utils/ServerApi";

export default {
  props: {},
  data() {
    return {
      resolveVisible: false,
      alertId: "",
      eventTypeMap: {
        1: '跌倒警報',
        2: '限制訪問警報',
        3: '非法入侵警報',
        4: '火災警報',
        5: '揮手警報',
      },
      alertInfo: {},
      resolveInfo: {
        status: 1,
      },
      resolveContent: "",
      callBackFun: {}
    }
  },
  computed: {
    nickname: {
      get() {
        return this.$store.getters.getNickname;
      }
    }
  },
  mounted() {
    console.log(this.alertId)
  },
  methods: {
    resolveEvent(id, alertInfo, callBackFun) {
      this.alertId = id;
      this.alertInfo = alertInfo;
      this.resolveVisible = true;
      this.callBackFun = callBackFun;
    },
    closeResolve() {
      this.resolveVisible = false;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    submit(num) {
      if (num === 1) {
        ServerApi.createAlertResolveInfoByAlertResolveInfoNoNeedResolved({
          alertId: this.alertId,
          resolveContent: this.resolveContent
        }).then(() => {
          this.resolveVisible = false;
          this.callBackFun();
        })
      }
      if (num === 2) {
        ServerApi.createAlertResolveInfoByAlertResolveInfoResolved({
          alertId: this.alertId,
          resolveContent: this.resolveContent
        }).then(() => {
          this.resolveVisible = false;
          this.callBackFun();
        })
      }
    }
  }
}
</script>
<style scoped>
.d-label {
  font-weight: 600;
  font-size: 15px;
  color: #000000;
}

.d-content {
  font-size: 15px;
  color: #1c1c1c;
}
</style>
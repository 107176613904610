import {createRouter, createWebHistory} from "vue-router";

const routes = [
    {
        path: "/",
        name: "/",
        redirect: "/dashboards/home",
    },

    //自定义页面
    {
        path: "/login",
        name: "Login Page",
        component: () =>
            import(/* webpackChunkName: "HomePage" */ "../pages/Login.vue"),
    },
    {
        path: "/dashboards/PatientInfo",
        name: "病人信息",
        component: () =>
            import(/* webpackChunkName: "HomePage" */ "../pages/PatientInfo.vue"),
    },
    {
        path: "/dashboards/DiseaseBank",
        name: "疾病庫",
        component: () =>
            import(/* webpackChunkName: "HomePage" */ "../pages/DiseaseBank.vue"),
    },
    {
        path: "/dashboards/Home",
        name: "數據統計",
        component: () =>
            import(/* webpackChunkName: "HomePage" */ "../pages/Home.vue"),
    },
    {
        path: "/dashboards/EmployeeManagement",
        name: "員工管理",
        component: () =>
            import(/* webpackChunkName: "HomePage" */ "../pages/EmployeeManagement.vue"),
    },
    {
        path: "/info/MQTTDemo",
        name: "攝像頭實時畫面",
        component: () =>
            import(/* webpackChunkName: "HomePage" */ "../pages/MQTTDemo.vue"),
    },
    {
        path: "/info/MultiScreen",
        name: "查看院舍",
        component: () =>
            import(/* webpackChunkName: "HomePage" */ "../pages/MultiScreen.vue"),
    },
    {
        path: "/info/RoomsInfo",
        name: "院舍管理",
        component: () =>
            import(/* webpackChunkName: "HomePage" */ "../pages/RoomsInfo.vue"),
    },
    {
        path: "/info/SingleRoom",
        name: "房間內容",
        component: () =>
            import(/* webpackChunkName: "HomePage" */ "../pages/SingleRoom.vue"),
    },
    /*    {
            path: "/info/StampDemo",
            name: "图章",
            component: () =>
                import(/!* webpackChunkName: "HomePage" *!/ "../pages/StampDemo.vue"),
        },*/
    {
        path: "/info/StampDemo",
        name: "图章",
        component: () =>
            import(/* webpackChunkName: "HomePage" */ "../pages/StampDemoNew.vue"),
    },
    {
        path: "/info/FallDownEvents",
        name: "跌倒事件",
        component: () =>
            import(/* webpackChunkName: "HomePage" */ "../pages/FallDownEvents.vue"),
    },
    {
        path: "/components/CameraReplay",
        name: "跌倒事件",
        component: () =>
            import(/* webpackChunkName: "HomePage" */ "../components/CameraReplay.vue"),
    },
    {
        path: "/info/PeopleDetails",
        name: "老人健康狀態",
        component: () =>
            import(/* webpackChunkName: "HomePage" */ "../pages/PeopleDetails.vue"),
    },
    {
        path: "/info/HistoryData",
        name: "歷史紀錄",
        component: () =>
            import(/* webpackChunkName: "HomePage" */ "../pages/HistoryData.vue"),
    },
    {
        path: "/info/RadarInfo",
        name: "雷達設備",
        component: () =>
            import(/* webpackChunkName: "HomePage" */ "../pages/RadarInfo.vue"),
    },
    {
        path: "/info/ChatgptTest",
        name: "ChatgptTest",
        component: () =>
            import(/* webpackChunkName: "HomePage" */ "../pages/ChatgptTest.vue"),
    }

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "active",
});

export default router;

<template>
  <li class="nav-item active">
    <router-link class="nav-link" :to="to">
      <span class="sidenav-mini-icon text-white">
      <span class="material-symbols-outlined my-icon">{{ miniIcon }}</span>
      </span>
      <span class="sidenav-normal me-3 ms-3 ps-1 text-white text-white">
        {{ text }}
      </span>
    </router-link>
  </li>
</template>
<script>
let empty = {name: ''};
export default {
  name: "SidenavItem",
  props: {
    to: {
      type: [Object, String],
      default: empty
    },
    miniIcon: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  }
};
</script>
<style scoped>
.my-icon{
  font-variation-settings: 'FILL' 0, 'wght' 200, 'GRAD' 0, 'opsz' 24;
  color: #ededed;
}
</style>

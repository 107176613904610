import api from "@/utils/JSLetoyService";

const ServerApi = {
    login(username,password) {
        return api.noAuthorizationRequest(
            "MyLogin/LoginUserByUsernameAndPassword",
            "POST",
            null,
            {username, password},)
    },
    getAllAltumAlertsByCurrentPageAndPageSize(currentPage, pageSize) {
        return api.authorizationRequest(
            "AltumAlert/GetAllAltumAlertsByCurrentPageAndPageSize",
            "POST",
            null,
            {currentPage, pageSize})
    },
    createAlertResolveInfoByAlertResolveInfoResolved(alertResolveInfo) {
        return api.authorizationRequest(
            "AlertResolveInfo/CreateAlertResolveInfoByAlertResolveInfoResolved",
            "POST",
            null,
            alertResolveInfo)
    },
    createAlertResolveInfoByAlertResolveInfoNoNeedResolved(alertResolveInfo) {
        return api.authorizationRequest(
            "AlertResolveInfo/CreateAlertResolveInfoByAlertResolveInfoNoNeedResolved",
            "POST",
            null,
            alertResolveInfo)
    },
    deleteAltumAlertByAltumAlertId(alertId) {
        return api.authorizationRequest(
            "AltumAlert/DeleteAltumAlertByAltumAlertId",
            "POST",
            null,
            {alertId})
    },
    homePageAnalyze() {
        return api.authorizationRequest(
            "DataAnalyze/HomePageAnalyze",
            "POST",
            null,
            null)
    },
    RadarInfo:{
        getAllDevices(){
            return api.authorizationRequest(
                "RadarInfo/GetAllDevices",
                "POST",
                null,
                null)
        }

    }
};
export default ServerApi;
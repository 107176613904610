import api from "@/utils/JSLetoyService";

const AlthumApi = {
    getUserAccountInfo() {
        return api.authorizationRequest(
            "ThirdPlatform/GetUserAccountInfo",
            "POST",
            null,
            null)
    },
    getCameraDataByCameraId(id) {
        return api.authorizationRequest(
            "ThirdPlatform/GetCameraDataByCameraId",
            "POST",
            null,
            {id})
    },
    getCameras() {
        return api.authorizationRequest(
            "ThirdPlatform/GetCameras",
            "POST",
            null,
            null)
    },
    getMQTTAccount() {
        return api.authorizationRequest(
            "ThirdPlatform/GetUserMQTTAccount",
            "POST",
            null,
            null)
    },
    getCameraBackgroundImageURL(id) {
        return api.authorizationRequest(
            "ThirdPlatform/GetCameraBackgroundImageURL",
            "POST",
            null,
            {id})
    },
    getSkeletonRecords(startDate, endDate) {
        return api.authorizationRequest(
            "ThirdPlatform/GetSkeletonRecords",
            "POST",
            null,
            {startDate, endDate})
    },
    getAlertByAlertId(id) {
        return api.authorizationRequest(
            "ThirdPlatform/GetAlertByAlertId",
            "POST",
            null,
            {id})
    },
    getAlerts() {
        return api.authorizationRequest(
            "ThirdPlatform/GetAlerts",
            "POST",
            null,
            null)
    },
    createCameraSteamToken(id) {
        return api.authorizationRequest(
            "ThirdPlatform/CreateCameraSteamToken",
            "POST",
            null,
            {id})
    },
    getFloorMaskByCameraId(id) {
        return api.authorizationRequest(
            "ThirdPlatform/GetFloorMaskByCameraId",
            "POST",
            null,
            {id})
    },
};
export default AlthumApi;